import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import FileInput from "../../components/inputs/FileInput";
import Button from "../../components/inputs/Button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import apiRequest from "../../lib/ApiRequest";
import toast from "react-hot-toast";
import PostImageCropper from "./components/PostImageCropper";

const AddPost = ({ closeModal }) => {
    const [postImg, setPostImg] = useState("");
    const [currentPage, setCurrentPage] = useState("choose-img");
    const [profileImgAfterCrop, setProfileImgAfterCrop] = useState("");
    const [postText, setPostText] = useState("");
    const queryClient = useQueryClient();

    const onImageSelected = (selectedImg) => {
        setPostImg(selectedImg);
        setCurrentPage("crop-img");
    };

    const onCropDone = (imgCroppedArea) => {
        const canvasEle = document.createElement("canvas");
        canvasEle.width = imgCroppedArea.width;
        canvasEle.height = imgCroppedArea.height;

        const context = canvasEle.getContext("2d");
        let imageObj1 = new Image();
        imageObj1.src = postImg;
        imageObj1.onload = function () {
            context.drawImage(
                imageObj1,
                imgCroppedArea.x,
                imgCroppedArea.y,
                imgCroppedArea.width,
                imgCroppedArea.height,
                0,
                0,
                imgCroppedArea.width,
                imgCroppedArea.height
            );
            const dataURL = canvasEle.toDataURL("image/jpeg");
            setProfileImgAfterCrop(dataURL);
            setCurrentPage("image-cropped");
        };
    };

    const onCropCancel = () => {
        setCurrentPage("choose-img");
        setPostImg("");
    };

    const handleTextChange = (e) => {
        setPostText(e.target.value);
    };

    // Mutation to upload the cropped image and text
    const { mutate: createPost } = useMutation({
        mutationFn: async ({ file, text }) => {
            const formData = new FormData();
            formData.append("postsImage", file);
            formData.append("text", text);

            const res = await apiRequest.post("/posts/create", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            return res.data;
        },
        onSuccess: (data) => {
            toast.success(data.message);
            queryClient.invalidateQueries({ queryKey: ["user"] });
            queryClient.invalidateQueries({ queryKey: ["authUser"] });
            queryClient.invalidateQueries({ queryKey: ["userPost"] });
            closeModal();
        },
        onError: (error) => {
            const message = error.response?.data?.message || "Something went wrong!";
            toast.error(message);
        },
    });

    return (
        <div className="relative z-10" aria-labelledby="crop-image-dialog" role="dialog" aria-modal="true">
            <div className="fixed inset-0 bg-mainColor-800/70 bg-opacity-75 transition-all backdrop-blur-sm"></div>
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full justify-center px-2 py-12 text-center">
                    <div className="relative top-20 w-[95%] sm:w-[80%] min-h-[60vh] rounded-2xl bg-background text-slate-100 text-left shadow-xl transition-all flex items-center justify-center">
                        <div className="px-5 py-4">
                            <button
                                type="button"
                                className="rounded-md p-1 inline-flex items-center justify-center text-gray-400 hover:bg-gray-700 focus:outline-none absolute top-2 right-2"
                                onClick={closeModal}
                            >
                                <span className="sr-only">Close menu</span>
                                <IoClose color="" />
                            </button>

                            <div>
                                {currentPage === "choose-img" ? (
                                    <FileInput onImageSelected={onImageSelected} label={"Vyber fotku alebo Video"} />
                                ) : currentPage === "crop-img" ? (
                                    <PostImageCropper
                                        image={postImg}
                                        onCropDone={onCropDone}
                                        onCropCancel={onCropCancel}
                                    />
                                ) : (
                                    <div className="">
                                        <div className="w-full top-20 mt-8 md:px-12 lg:px-16 xl:px-72 shadow-lg p-6 bg-background rounded-lg">
                                            <img
                                                src={profileImgAfterCrop}
                                                alt="Profile"
                                                className="mt-4"
                                            />
                                            <input
                                                type="text"
                                                value={postText}
                                                onChange={handleTextChange}
                                                placeholder="Napíšte text príspevku..."
                                                className="mt-4 w-full p-2 border border-gray-300 rounded-lg text-black"
                                            />
                                        </div>
                                        <div className="flex justify-center gap-2">
                                            <Button
                                                label="Vybrať iný"
                                                onClick={() => {
                                                    setCurrentPage("choose-img");
                                                    setPostImg("");
                                                }}
                                            />
                                            <Button
                                                label="Uložiť Príspevok"
                                                onClick={() => {
                                                    const byteString = atob(profileImgAfterCrop.split(",")[1]);
                                                    const mimeString = profileImgAfterCrop.split(",")[0].split(":")[1].split(";")[0];
                                                    const arrayBuffer = new ArrayBuffer(byteString.length);
                                                    const uint8Array = new Uint8Array(arrayBuffer);
                                                    for (let i = 0; i < byteString.length; i++) {
                                                        uint8Array[i] = byteString.charCodeAt(i);
                                                    }
                                                    const blob = new Blob([uint8Array], { type: mimeString });
                                                    const file = new File([blob], "profile.jpg", { type: mimeString });

                                                    createPost({ file, text: postText });
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddPost;
