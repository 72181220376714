import { useField } from "formik";
import React from "react";

const Select = ({
  label,
  leftIcon: LeftIcon,
  rightIcon: RightIcon,
  onIconClick,
  children,
  ...props
}) => {
  const [field, meta] = useField(props);

  return (
    <div className="mb-4">
      <label className="block text-sm font-semibold text-primaryText">
        {label}
      </label>
      <div className="relative mt-1">
        {LeftIcon && (
          <LeftIcon
            size={24}
            className="absolute left-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
          />
        )}
        <select
          {...field}
          {...props}
          className={`block w-full ${
            LeftIcon ? 'pl-10' : 'pl-3' // Adjust padding-left if LeftIcon is present
          } ${
            RightIcon ? 'pr-10' : 'pr-3' // Adjust padding-right if RightIcon is present
          } py-2 border ${
            meta.touched && meta.error ? "border-red-500" : "border-gray-300"
          } rounded-md shadow-sm focus:outline-none focus:ring-mainColor-500 focus:border-mainColor-500 sm:text-md`}
        >
          {children}
        </select>
        {RightIcon && (
          <RightIcon
            size={24}
            className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
            onClick={onIconClick}
          />
        )}
      </div>
      {meta.touched && meta.error && (
        <p className="mt-2 text-sm text-red-600">{meta.error}</p>
      )}
    </div>
  );
};

export default Select;
