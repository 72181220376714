import React, { useState } from "react";
import Input from "../../inputs/Input";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import Button from "../../inputs/Button";
import Select from "../../inputs/Select";
import {
  isValidPhoneNumber
} from "react-phone-number-input";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { useMutation } from "@tanstack/react-query";
import apiRequest from "../../../lib/ApiRequest";
import toast from "react-hot-toast";
import autoformAPI from "../../../lib/autoformApi";

const Register = ({ closeDialog }) => {
  const [visible, setVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [isBusiness, setIsBusiness] = useState(true);
  const [businessName, setBusinessName] = useState("");
  const [results, setResults] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);


  const initialValues = {
    isSupplier: "",
    firstName: "",
    lastName: "",
    email: email,
    password: "",
    re_password: "",
    phone: "",
    businessName: "",
    city: "",
    street: "",
    postalCode: "",
    country: "",
  };

  const validationSchemaNoSupplier = yup.object({
    userType: yup.string("supplier"),
    email: yup
      .string()
      .email("Zlý formát emailu")
      .required("Zadaj svoj email"),
    phone: yup
      .string()
      .test("isValidPhoneNumber", "Zadaj správne telefónne číslo", (value) =>
        isValidPhoneNumber(value)
      )
      .required("Telefónne číslo je povinné"),
    password: yup
      .string()
      .required("Zadaj heslo")
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        "Musí obsahovať minimálne 8 znakov, jedno veľké písmeno, jedno malé písmeno, jedno číslo a jeden špeciálny znak"
      ),
    re_password: yup
      .string()
      .oneOf([yup.ref("password"), null], "Heslá sa musia zhodovať")
      .required("Zopakuj heslo"),
  });

  const validationSchemaSupplier = yup.object({
    ...validationSchemaNoSupplier.fields,
    businessName: yup.string().required("Názov firmy je povinný"),
    city: yup.string().required("Mesto je povinné"),
    street: yup.string().required("Ulica je povinná"),
    postalCode: yup.string().required("PSČ je povinné"),
    country: yup.string().required("Krajina je povinná"),
  });

  const toggleVisibility = () => {
    setVisible(!visible);
  };

  const { mutate: registerMutation } = useMutation({
    mutationFn: async (values) => {
      try {
        const res = await apiRequest.post("/auth/register", {
          isSupplier: isBusiness,
          email: values.email.toLowerCase(),
          phoneNumber: phone,
          firstName: values.firstName,
          lastName: values.lastName,
          password: values.password,
          companyName: values.businessName,
          address: values.street,
          city: values.city,
          psc: values.postalCode,
          country: values.country,
        });
        if (res.status !== 200 && res.status !== 201) {
          throw new Error("Failed to Register.");
        }
        return res.data;
      } catch (error) {
        throw error.response?.data || error.message || error;
      }
    },
    onSuccess: (data) => {
      toast.success(data.message || "Registration successful!");
      closeDialog();
    },
    onError: (error) => {
      const message = error.message || "Something went wrong!";
      toast.error(message);
    },
  });

  // const { data: promoCodeData } = useQuery({
  //   queryKey: ["promoCode", email],
  //   queryFn: async () => {
  //     if (!email) return null;
  //     const response = await apiRequest.get("emailCollect/getPromoCode", {
  //       params: { email },
  //     });
  //     return response.data.promoCode;
  //   },
  //   enabled: !!email,
  // });

  const handleInputChange = async (e) => {
    const value = e.target.value;
    setBusinessName(value);

    if (value.length > 1) {
      try {
        const response = await autoformAPI.get("/search", {
          params: {
            q: `name:${value}`,
            private_access_token: "f92c4467eadf9300b9acf307f332d7d4d6b7fbca5add7eb2b96e57e3e4391210a784b65d07d9e3ff",
            limit: 20
          },
        });
        setResults(response.data);
        setShowDropdown(true);
      } catch (err) {
        setResults([]);
        setShowDropdown(false);
      }
    } else {
      setResults([]);
      setShowDropdown(false);
    }
  };

  const handleResultSelect = (result, setFieldValue) => {
    setBusinessName(result.name);
    setShowDropdown(false);
    // Use setFieldValue to update the Formik fields
    setFieldValue("businessName", result.name || "")
    setFieldValue("city", result.municipality || "");
    setFieldValue("street", result.formatted_street || "");
    setFieldValue("postalCode", result.postal_code || "");
    setFieldValue("country", result.country || "");
  };

  return (
    <div className="bg-background p-8 max-w-md w-full">
      <h2 className="text-2xl font-bold text-mainColor-600 text-center">
        Vytvorte si konto
      </h2>
      <Formik
        initialValues={initialValues}
        validationSchema={isBusiness ? validationSchemaSupplier : validationSchemaNoSupplier}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          registerMutation(values, {
            onSettled: () => setSubmitting(false),
          });
        }}
      >
        {({ errors, touched, setFieldValue }) => (
          <Form id="register">
            <Select
              label="Typ účtu"
              name="userType"
              onChange={(e) => {
                const value = e.target.value;
                setIsBusiness(value === "supplier");
                setFieldValue("isSupplier", value === "supplier");
              }}
            >
              <option value="type" disabled>
                Vyber typ účtu
              </option>
              <option value="supplier">Ponúkať služby</option>
              <option value="client">Hľadať služby</option>
            </Select>
            <Input name="firstName" label="Meno" />
            <Input name="lastName" label="Priezvisko" />
            <Input
              name="email"
              label="Email"
              id="email"
              onChange={(e) => {
                const emailValue = e.target.value;
                setFieldValue("email", emailValue);
                setEmail(emailValue);
              }}
            />
            <Input
              name="password"
              label="Heslo"
              type={visible ? "text" : "password"}
              rightIcon={visible ? MdVisibility : MdVisibilityOff}
              onIconClick={toggleVisibility}
            />
            <Input
              name="re_password"
              label="Zopakuj heslo"
              type={visible ? "text" : "password"}
              rightIcon={visible ? MdVisibility : MdVisibilityOff}
              onIconClick={toggleVisibility}
            />
            <div className="mb-4">
              <label className="block text-sm font-semibold text-primaryText">
                Telefonné číslo
              </label>
              <div className="block w-full mt-1 mb-4 bg-white p-3 rounded-md shadow-sm focus:outline-none focus:ring-mainColor-500 focus:border-mainColor-500 sm:text-md">
                <PhoneInput
                  placeholder="Zadaj telefónne číslo"
                  value={phone}
                  onChange={(value) => {
                    setPhone(value);
                    setFieldValue("phone", value);
                  }}
                  error={
                    touched.phone && errors.phone ? errors.phone : undefined
                  }
                />
              </div>
            </div>
            {isBusiness && (
              <>
                <Input
                  name="businessName"
                  label="Názov firmy"
                  value={businessName}
                  onChange={handleInputChange}
                />
                {showDropdown && (
                  <div className="relative w-full bg-background border border-mainColor-500 rounded-md z-1000">
                    {results.length > 0 ? (
                      results.map((result, index) => (
                        <div
                          key={index}
                          className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                          onClick={() => handleResultSelect(result, setFieldValue)} // Explicitly pass setFieldValue
                        >
                          <strong>{result.name}</strong>
                          <p className="text-sm text-gray-500">{result.formatted_address}</p>
                        </div>
                      ))
                    ) : (
                      <div className="p-4 text-gray-500">Nenašli sa žiadne výsledky.</div>
                    )}
                  </div>
                )}
                <Input name="city" label="Mesto" />
                <Input name="street" label="Ulica" />
                <Input name="postalCode" label="PSČ" />
                <Input name="country" label="Krajina" />
              </>
            )}
            <Button label="Zaregistrovať sa" type="submit" />
          </Form>
        )}
      </Formik>
      <p className="text-xs text-gray-500 text-center mt-4">
        Pokračovaním súhlasíte s{" "}
        <a href="/" className="text-mainColor-500 hover:underline">
          Podmienkami služby
        </a>{" "}
        a{" "}
        <a href="/" className="text-mainColor-500 hover:underline">
          Zásadami ochrany osobných údajov
        </a>{" "}
        spoločnosti TAPROFI.
      </p>
    </div>
  );
};

export default Register;
