import { useCallback, useEffect, useState, useRef } from "react";
import { useParams, useOutletContext } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import apiRequest from "../../lib/ApiRequest";
import { FaRegHeart, FaRegComment } from "react-icons/fa";
import AddPost from "../../components/AddPost/AddPost";
import { IoMdAddCircleOutline, IoMdArrowUp, IoMdArrowDown } from "react-icons/io";
import Calendar from "../../components/Calendar/Calendar";
import Review from "../../components/Review/Review";
import Button from "../../components/inputs/Button";
import CustomerCalendar from "../../components/Calendar/components/CustomerCalendar";

const ProfilePage = () => {
  const { profileId } = useParams();
  const { data: authUser } = useQuery({ queryKey: ["authUser"] });
  const { data: user } = useQuery({ queryKey: ["user"] });
  const { selectedTab } = useOutletContext();
  const modalRef = useRef(null); // Reference for scroll control
  const [addPostModalOpen, setAddPostModalOpen] = useState(false);
  const [viewPostModalOpen, setViewPostModalOpen] = useState(false);
  const [selectedPostIndex, setSelectedPostIndex] = useState(0);

  // Fetch user posts
  const { data: userPosts = [], refetch } = useQuery({
    queryKey: ["userPost", profileId],
    queryFn: async () => {
      try {
        const res = await apiRequest.get(`posts/user/${profileId}`);
        if (res.status !== 200) throw new Error("Failed to fetch user posts.");
        return res.data || [];
      } catch (error) {
        console.error("Error fetching posts:", error);
        return [];
      }
    },
  });

  useEffect(() => {
    refetch();
  }, [profileId, refetch]);

  const nextPost = useCallback(() => {
    setSelectedPostIndex((prevIndex) => prevIndex + 1);
  }, []);
  
  // Navigate to the previous post
  const prevPost = useCallback(() => {
    setSelectedPostIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  }, []);

  const handleScroll = useCallback(
    (event) => {
      if (!viewPostModalOpen) return;
      event.preventDefault();
  
      if (event.deltaY > 0) {
        nextPost();
      } else if (event.deltaY < 0) {
        prevPost();
      }
    },
    [viewPostModalOpen, nextPost, prevPost] // Dependencies are now stable
  );
  const IsMyProfile = authUser?._id === profileId;

  // Open modal for adding a post
  const openAddPostModal = () => setAddPostModalOpen(true);
  const closeAddPostModal = () => setAddPostModalOpen(false);

  // Open modal for viewing posts
  const openViewPostModal = (index) => {
    setSelectedPostIndex(index);
    setViewPostModalOpen(true);
    document.body.style.overflow = "hidden"; // Disable background scrolling
  };

  const closeViewPostModal = () => {
    setViewPostModalOpen(false);
    document.body.style.overflow = "auto"; // Enable scrolling
  };

  
// Attach the event listener properly
useEffect(() => {
  if (viewPostModalOpen) {
    window.addEventListener("wheel", handleScroll);
  } else {
    window.removeEventListener("wheel", handleScroll);
  }

  return () => window.removeEventListener("wheel", handleScroll);
}, [viewPostModalOpen, handleScroll]); // Dependencies are now stable

  return (
    <div className="flex flex-col gap-6 items-center">
      {selectedTab === "posts" && (
        <>
          {IsMyProfile && (
            <div className="w-1/3">
              <Button label="Pridať prispevok" icon={IoMdAddCircleOutline} onClick={openAddPostModal} />
            </div>
          )}

          {/* Posts Grid */}
          <div className="grid grid-cols-3 gap-4 mt-4 justify-center items-center">
            {userPosts.length > 0 ? (
              userPosts.map((post, index) => (
                <div
                  key={post._id}
                  className="relative bg-white rounded-lg overflow-hidden shadow-md cursor-pointer transition-transform transform hover:scale-105"
                  onClick={() => openViewPostModal(index)} // Opens post modal
                >
                  <img
                    src={post?.img || "https://via.placeholder.com/300"}
                    alt={`Post ${post?._id}`}
                    className="w-full h-full object-cover aspect-square rounded-lg"
                  />
                  <div className="absolute inset-0 bg-black bg-opacity-40 opacity-0 transition-opacity duration-300 flex items-center justify-center text-white text-2xl font-bold hover:opacity-100">
                    <span>
                      <FaRegHeart /> {post?.likes?.length || 0}
                    </span>
                    <span className="ml-4">
                      <FaRegComment /> {post?.comments?.length || 0}
                    </span>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-center text-lg text-gray-500 mt-4">No posts available.</p>
            )}
          </div>
        </>
      )}

      {selectedTab === "calendar" && (
        <div className="w-full h-[100vh] grid place-items-center">
          {user?.isSupplier ? <Calendar user={user} /> : <CustomerCalendar />}
        </div>
      )}

      {selectedTab === "ratings" && <div><Review /></div>}

      {/* Add Post Modal */}
      {addPostModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg p-6 shadow-lg">
            <AddPost closeModal={closeAddPostModal} />
          </div>
        </div>
      )}

      {/* View Post Modal with Vertical Navigation */}
      {viewPostModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div
            className="relative bg-white rounded-lg p-6 w-full max-w-2xl shadow-lg flex flex-col justify-center items-center"
            ref={modalRef}
          >
            {/* Close Button */}
            <button onClick={closeViewPostModal} className="absolute top-2 right-4 text-gray-600 hover:text-gray-900 text-2xl">
              ✕
            </button>

            {/* Up Button */}
            {selectedPostIndex > 0 && (
              <button onClick={prevPost} className="absolute top-6 left-1/2 transform -translate-x-1/2 text-gray-600 hover:text-gray-900 text-3xl">
                <IoMdArrowUp />
              </button>
            )}

            {/* Post Content */}
            <div className="flex flex-col items-center">
              <img src={userPosts[selectedPostIndex]?.img} alt="Post" className="rounded-lg w-full" />
              <h2 className="text-xl font-bold mt-4">
                {userPosts[selectedPostIndex]?.text || "No description"}
              </h2>
              <div className="flex items-center mt-2 text-gray-500">
                <span className="mr-4 flex items-center">
                  <FaRegHeart className="mr-1" /> {userPosts[selectedPostIndex]?.likes?.length || 0}
                </span>
                <span className="flex items-center">
                  <FaRegComment className="mr-1" /> {userPosts[selectedPostIndex]?.comments?.length || 0}
                </span>
              </div>
            </div>

            {/* Down Button */}
            {selectedPostIndex < userPosts.length - 1 && (
              <button onClick={nextPost} className="absolute bottom-6 left-1/2 transform -translate-x-1/2 text-gray-600 hover:text-gray-900 text-3xl">
                <IoMdArrowDown />
              </button>
            )}
          </div>
        </div>
      )}
      <div className="gap-6">
      <p className="text-gray-600 text-sm">viktor text</p>
</div>
    </div>
  );
};

export default ProfilePage;
