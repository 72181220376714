import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import { FaRegHeart, FaRegComment } from "react-icons/fa";
import apiRequest from "../../../lib/ApiRequest";

const PostDetailPage = () => {
  const { postId } = useParams();

  const { data: post, isLoading, error } = useQuery({
    queryKey: ["post", postId],
    queryFn: async () => {
      const res = await apiRequest.get(`/posts/${postId}`);
      return res.data;
    },
  });

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error loading post.</p>;

  return (
    <div className="flex flex-col items-center p-6">
      <div className="w-full max-w-2xl bg-white rounded-lg shadow-lg">
        <img src={post?.img} alt="PostImage" className="w-full rounded-lg" />
        <div className="p-4">
          <h2 className="text-xl font-bold">{post?.title || "Post"}</h2>
          <p className="text-gray-600 mt-2">{post?.description}</p>
          <div className="mt-4 flex items-center space-x-4">
            <span className="flex items-center text-gray-500">
              <FaRegHeart className="mr-1" /> {post?.likes?.length || 0}
            </span>
            <span className="flex items-center text-gray-500">
              <FaRegComment className="mr-1" /> {post?.comments?.length || 0}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostDetailPage;
