import React, { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import apiRequest from "../../../lib/ApiRequest";
import toast from "react-hot-toast";

const WorkingHoursSetup = () => {
  const [error, setError] = useState("");

  // Initialize working hours (default: Mon-Fri 08:00-18:00, Sat-Sun closed)
  const [workingDays, setWorkingDays] = useState(
    Array(7)
      .fill(null)
      .map((_, index) => ({
        isChecked: index < 5, // Monday-Friday checked by default
        startTime: index < 5 ? "08:00" : "",
        endTime: index < 5 ? "18:00" : "",
      }))
  );

  const { mutate: updateWorkingHours, isLoading } = useMutation({
    mutationFn: async (data) => {
      const response = await apiRequest.put("/appointment/workingHours", data);
      return response.data;
    },
    onSuccess: () => {
      toast.success("Pracovný čas bol úspešne uložený!");
    },
    onError: (error) => {
      setError(`Chyba pri ukladaní: ${error.response?.data?.error || error.message}`);
    },
  });

  // ✅ Mapping to display Sunday last but still send `0` in backend
  const daysOfWeek = ["Pondelok", "Utorok", "Streda", "Štvrtok", "Piatok", "Sobota", "Nedeľa"];
  const backendDayMapping = [6, 0, 1, 2, 3, 4, 5]; // Maps UI order to backend indices

  const toggleDay = (index) => {
    setWorkingDays((prev) =>
      prev.map((day, i) =>
        i === index
          ? {
              ...day,
              isChecked: !day.isChecked,
              startTime: !day.isChecked ? "08:00" : "",
              endTime: !day.isChecked ? "18:00" : "",
            }
          : day
      )
    );
  };

  const updateTime = (index, timeType, value) => {
    setWorkingDays((prev) =>
      prev.map((day, i) =>
        i === index
          ? {
              ...day,
              [timeType]: value,
            }
          : day
      )
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate time ranges
    const invalidTimes = workingDays.filter(
      (day) => day.isChecked && day.startTime && day.endTime && day.startTime >= day.endTime
    );

    if (invalidTimes.length > 0) {
      return toast.error("Zadajte platné časové rozpätie pre pracovný deň.");
    }

    // ✅ Ensures correct backend format (0 = Sunday, ..., 6 = Saturday)
    const hours = backendDayMapping.map((backendIndex, displayIndex) =>
      workingDays[backendIndex].isChecked
        ? {
            day: displayIndex === 6 ? 0 : displayIndex + 1, // Ensure Sunday is 0
            start: workingDays[backendIndex].startTime,
            end: workingDays[backendIndex].endTime,
          }
        : null
    );

    console.log("Odosielané dáta:", { workingHours: hours });
    updateWorkingHours({ workingHours: hours });
  };

  return (
    <div className="bg-white w-full rounded-lg shadow-lg p-6">
       {/* Progress bar */}
       <div className="flex items-center justify-between mb-6 space-x-4">
        <p className="text-lg font-bold text-mainColor-500">Nastavenie služieb</p>
        <p className="text-sm text-gray-600">1 / 3</p>
      </div>
      <div className="w-full bg-gray-200 rounded-full h-2 mb-4">
          <div className="bg-mainColor-500 h-2 rounded-full w-1/3"></div>
        </div>
      
      <div className="bg-blue-100 p-4 rounded-lg border border-blue-300 mb-6">
        <p className="text-sm text-blue-700 font-medium">
          Nastavte Váš pracovný čas. Vaše služby budú pre zákazníkov dostupné iba počas pracovného času. 
          Toto nastavenie môžete v budúcnosti zmeniť v sekcii <strong>Pracovný čas</strong>.
        </p>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 gap-4">
          {daysOfWeek.map((day, displayIndex) => {
            const backendIndex = backendDayMapping[displayIndex]; // Get correct backend index

            return (
              <div key={backendIndex} className="flex items-center">
                <label className="w-1/3 text-sm font-medium text-gray-700">{day}</label>
                <div className="flex items-center w-2/3 space-x-2">
                  <input
                    type="checkbox"
                    className="form-checkbox h-5 w-5 text-blue-500"
                    checked={workingDays[backendIndex].isChecked}
                    onChange={() => toggleDay(backendIndex)}
                  />
                  <input
                    type="time"
                    className={`w-1/3 border-gray-300 rounded-md shadow-sm ${
                      !workingDays[backendIndex].isChecked ? "bg-gray-100 cursor-not-allowed" : ""
                    }`}
                    value={workingDays[backendIndex].startTime}
                    onChange={(e) => updateTime(backendIndex, "startTime", e.target.value)}
                    disabled={!workingDays[backendIndex].isChecked}
                  />
                  <span>-</span>
                  <input
                    type="time"
                    className={`w-1/3 border-gray-300 rounded-md shadow-sm ${
                      !workingDays[backendIndex].isChecked ? "bg-gray-100 cursor-not-allowed" : ""
                    }`}
                    value={workingDays[backendIndex].endTime}
                    onChange={(e) => updateTime(backendIndex, "endTime", e.target.value)}
                    disabled={!workingDays[backendIndex].isChecked}
                  />
                </div>
              </div>
            );
          })}
        </div>

        {error && <p className="text-red-500 mt-2">{error}</p>}

        <div className="flex justify-end mt-6">
          <button
            type="submit"
            disabled={isLoading}
            className="bg-green-500 text-white font-bold py-2 px-6 rounded-lg hover:bg-green-600"
          >
            {isLoading ? "Ukladá sa..." : "Uložiť"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default WorkingHoursSetup;
