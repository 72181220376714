import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { GoPencil } from "react-icons/go";
import { FiCheck, FiX } from "react-icons/fi";
import apiRequest from "../../../../lib/ApiRequest";
import toast from "react-hot-toast";

const Bio = ({ closeModal, bio, IsMyProfile }) => {
  const queryClient = useQueryClient();
  const [isEditing, setIsEditing] = useState(false);
  const [bioValue, setBioValue] = useState(bio || ""); // Ensure proper initial state

  // Mutation to update the bio
  const { mutate: updateBIO, isLoading } = useMutation({
    mutationFn: async ({ bio }) => {
      try {
        const res = await apiRequest.post("users/update", { data: { bio } });
        if (res.status !== 200) throw new Error("Failed to save BIO.");
        return res.data;
      } catch (error) {
        console.error("Error during updateBIO mutation:", error.message);
        throw error;
      }
    },
    onSuccess: () => {
      toast.success("BIO bolo uložené!");
      setIsEditing(false); // Exit edit mode
      queryClient.invalidateQueries({ queryKey: ["user"] }); // Refresh user data
      if (closeModal) closeModal(); // Close modal if needed
    },
    onError: () => {
      toast.error("Chyba pri aktualizácii BIO. Skúste to znova.");
    },
  });

  const handleSaveBio = () => {
    if (bioValue.trim() !== "") {
      updateBIO({ bio: bioValue });
    } else {
      toast.error("Bio nemôže byť prázdne.");
    }
  };

  return (
    <div className="relative w-full px-6">
      <h2 className="text-xl font-semibold text-mainColor-500">O firme</h2>

      {/* Editing Mode */}
      {isEditing ? (
        <div className="mt-2">
          <textarea
            className="w-full p-2 bg-gray-700 rounded-md text-white focus:outline-none whitespace-pre-wrap"
            rows="10"
            value={bioValue}
            onChange={(e) => setBioValue(e.target.value)}
          />
          <div className="flex justify-end gap-2 mt-2">
            <button
              className="px-4 py-2 bg-green-500 text-white rounded-md flex items-center gap-1 hover:bg-green-600"
              onClick={handleSaveBio}
              disabled={isLoading}
            >
              <FiCheck /> {isLoading ? "Ukladám..." : "Uložiť"}
            </button>
            <button
              className="px-4 py-2 bg-red-500 text-white rounded-md flex items-center gap-1 hover:bg-red-600"
              onClick={() => setIsEditing(false)}
            >
              <FiX /> Zrušiť
            </button>
          </div>
        </div>
      ) : (
        // Viewing Mode (Preserve Formatting)
        <div className="mt-2">
          <p className="text-primaryText whitespace-pre-wrap">{bio || "Žiadny popis nebol pridaný."}</p>
        </div>
      )}

      {/* Edit Button (Visible Only for Profile Owner) */}
      {IsMyProfile && !isEditing && (
        <button
          className="absolute -bottom-6 right-0 m-auto w-fit p-3 rounded-full bg-white hover:bg-background border-2 border-mainColor-500 text-mainColor-500 transition-all duration-300 shadow-md"
          title="Upraviť bio"
          onClick={() => setIsEditing(true)}
        >
          <GoPencil className="text-xl" />
        </button>
      )}
    </div>
  );
};

export default Bio;
