import React from "react";
import logoHlavne from "../../../assets/logoHlavne.png";

const Header = () => {
  return (
    <div className="flex flex-col items-center">
      <img src={logoHlavne} alt="HlavneLogo" width={80} height={80} />
      <div className="font-bold">Vitaj v TAPROFI</div>
    </div>
  );
};

export default Header;
