import React, { useState } from 'react';
import DateAndTime from "./components/DateAndTime";
import Services from './components/Services';
import Button from '../inputs/Button';
import toast from 'react-hot-toast';
import apiRequest from '../../lib/ApiRequest';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import AddServices from './setupComponents/AddServices';
import WorkingHoursSetup from './setupComponents/WorkingHours';
import CustomerCalendar from "../../components/Calendar/components/CustomerCalendar";
import { BsGear } from 'react-icons/bs';
import CalendarSettings from './setupComponents/CalendarSettings';

const Calendar = ({ user,   }) => {
  const [selectedService, setSelectedService] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState("");
  const { profileId } = useParams();
  const { data: authUser } = useQuery({ queryKey: ["authUser"] });
  const queryClient = useQueryClient();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [step, setStep] = useState(1);

const onClose = () =>{
  setIsModalOpen(false)
}

  const { data: workingHours = [] } = useQuery({
    queryKey: ["workingHours", profileId],
    queryFn: async () => {
      const res = await apiRequest.get(`/appointment/${profileId}`);
      return res.data?.workingHours || [];
    },
  });

  // Načítanie služieb
  const { data: userServices = [] } = useQuery({
    queryKey: ["userServices", profileId],
    queryFn: async () => {
      const res = await apiRequest.get(`/services/${profileId}`);
      return res.data || [];
    },
  });

  const { data: reservationSettings } = useQuery({
    queryKey: ["reservationSettings", profileId],
    queryFn: async () => {
      const res = await apiRequest.get(`reservationSettings/${profileId}`);
      return res.data;
    },
  });


  const { mutate: updateBooking } = useMutation({
    mutationFn: async ({ booking }) => {
      try {
        const res = await apiRequest.post("users/update", { data: { booking } }); // ✅ Opravené: pridanie "data"
        if (res.status !== 200) throw new Error("Failed to save booking.");
        return res.data;
      } catch (error) {
        console.error("Error during updateBooking mutation:", error.message);
        throw error;
      }
    },
    onSuccess: () => {
      toast.success("Rezerváčný system bol úspešne aktualizovaný!");
      queryClient.invalidateQueries({ queryKey: ["user"] });
      onClose();
    },
    onError: (error) => {
      toast.error(`Chyba pri nastavovaní rezervácií: ${error.message}`);
    },
  });

  // Kontrola, či má užívateľ nastavené pracovné hodiny a aspoň jednu službu
  const hasWorkingHours = workingHours.length > 0;
  const hasServices = Array.isArray(userServices) && userServices.length > 0;
  const canFinishSetup = hasWorkingHours && hasServices;

  const handleServiceSelect = (service) => {
    setSelectedService(service);
  };

  const handleDateSelect = (date) => {
    setSelectedDate(date);
  };

  const handleTimeSelect = (time) => {
    setSelectedTime(time);
  };

  const { mutate: bookAppointment } = useMutation({
    mutationFn: async () => {
      const response = await apiRequest.post("/appointment/book-appointment", {
        supId: profileId,
        serviceId: selectedService._id,
        slotDate: selectedDate,
        slotTime: selectedTime,
        duration: selectedService.duration,
        interval: reservationSettings.timeSlotInterval
      });
      return response.data;
    },
    onSuccess: (data) => {
      if (data.success) {
        toast.success("Rezervácia bola úspešná!");
      } else {
        toast.error(data.message || "Rezervácia zlyhala.");
      }
      queryClient.invalidateQueries({ queryKey: ["bookedSlots"] });
    },
    onError: (error) => {
      console.error("Booking error:", error);
      toast.error("Nastala chyba pri rezervácii.");
    }
  });

  const handleBooking = () => {
    if (!selectedService || !selectedDate || !selectedTime) {
      toast.error("Prosím, vyberte službu, dátum a čas.");
      return;
    }
    bookAppointment();
  };

  const IsMyProfile = authUser?._id === user?._id;

  return (
    <>
      {user?.booking ? (
        <div>
          {IsMyProfile ? (
            <>
              <Button label="Upraviť nastavenia Kalendára" icon={BsGear} onClick={() => setIsModalOpen(true)} />
              <CustomerCalendar />
            </>
          ) : (
            <>
              <Services onSelect={handleServiceSelect} />
              <DateAndTime selectedService={selectedService} onDateSelect={handleDateSelect} onTimeSelect={handleTimeSelect} />
              <Button label="Rezervovať" onClick={handleBooking} />
              
            </>
          )}
        </div>
      ) : (
        IsMyProfile ? (
          <Button label="Vytvoriť rezervačný systém" onClick={() => setIsModalOpen(true)} />
        ) : (
          <p>Užívateľ nemá vytvorený rezervačný systém</p>
        )
      )}

{isModalOpen && (
        <div className="fixed inset-0 bg-mainColor-800/70 bg-opacity-75 transition-all backdrop-blur-sm">
          <div className="fixed inset-[80px] z-10 w-screen overflow-y-auto">
            <div className="flex justify-center px-2 py-12">
              <div className="transition-all duration-300 transform bg-background shadow-lg rounded-lg p-6 w-full max-w-6xl">
                {/* Krok 1: Nastavenie pracovných hodín */}
                {step === 1 && (
                  <div>
                    <h2 className="text-lg font-semibold">Nastavenie otváracích hodín</h2>
                    <WorkingHoursSetup />
                    <div className="flex justify-between mt-4">
                      <Button label="Ďalej" onClick={() => setStep(2)} />
                    </div>
                  </div>
                )}

                {/* Krok 2: Pridanie služby */}
                {step === 2 && (
                  <div>
                    <h2 className="text-lg font-semibold">Pridanie služby</h2>
                    <AddServices onSelect={setSelectedService} user={user} />
                    <div className="flex justify-between mt-4">
                      <Button label="Späť" onClick={() => setStep(1)} />
                      <Button label="Ďalej" onClick={() => setStep(3)} disabled={!hasServices} />
                    </div>
                  </div>
                )}

                {/* Krok 3: Nastavenia kalendára */}
                {step === 3 && (
                  <div>
                    <h2 className="text-lg font-semibold">Nastavenia kalendára</h2>
                    <CalendarSettings />
                    <div className="flex justify-between mt-4">
                      <Button label="Späť" onClick={() => setStep(2)} />
                      <Button label="Hotovo" onClick={() => updateBooking({ booking: true })} disabled={!canFinishSetup} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Calendar;