import React from "react";

const Button = ({
  label,
  type,
  onClick,
  disabled,
  outline,
  small,
  follow,
  icon: Icon,
  iconColor,
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={`relative disabled:opacity-70 disabled:cursor-not-allowed rounded-lg hover:opacity-80 transition p-10
      ${outline ? "bg-background" : "bg-mainColor-500"}
      ${
        outline
          ? " border-slate-300 hover:bg-slate-700 text-slate-500"
          : "border-mainColor-500  hover:bg-slate-700"
      } 
      ${outline ? " hover:text-white" : "text-white"} 
      ${small ? "py-1" : "py-3"}
      ${small ? "text-sm" : "text-md"}
      ${small ? "font-light" : "font-semibold"}
      ${small ? "border-[1px]" : "border-2"}
      ${small ? "btn-sm" : ""}
      ${small ? "px-4" : "w-full"}
      ${follow ? "bg-mainColor rounded-3xl hover:bg-mainColor-600 hover:text-white transition-all" : ""}
      `}
    >
      {Icon && (
        <Icon size={24} color={iconColor} className=" absolute left-4 top-3" />
      )}
      {label}
    </button>
  );
};

export default Button;
