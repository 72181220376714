import Navbar from '../components/Navbar/Navbar'
import { Toaster } from "react-hot-toast";
import { Outlet } from "react-router-dom";

import { useQuery } from '@tanstack/react-query';
import apiRequest from '../lib/ApiRequest';
import AuthWrapper from '../components/auth/AuthWrapper';
import useHeader from "../hooks/UseHeader";

const DashBoard = () => {
    const { modalOpen, toggleModal } = useHeader(); 
    

  const { data: authUser } = useQuery({
    queryKey: ["authUser"],
    queryFn: async () => {
      try {
        const res = await apiRequest.get("auth/me");
        const data = res.data;
        if (res.status !== 200) {
          throw new Error("Failed to fetch authenticated user.");
        }
       return data;
      } catch (error) {
        throw error; // Let useQuery handle the error
      }
    },
  });


  return (
   <>
  {!authUser && <AuthWrapper modalOpen={modalOpen} toggleModal={toggleModal} />}
  <div className="shadow-slate-200">
   <Toaster position="bottom-left" reverseOrder={false} />
   <Navbar modalOpen={authUser} />
   </div>
   {/* <div className="flex flex-col w-full h-full bg-gradient-to-t from-mainColor-100 to-gray-50"> */}
    <Outlet />
   {/* </div> */}
   </>
  )
}

export default DashBoard