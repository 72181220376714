import React, { useState } from "react";
import Calendar from "react-calendar";
import { motion } from "framer-motion";

const events = [
  { time: "10:00", color: "bg-mainColor-500" },
  { time: "11:00", color: "bg-mainColor-500" },
  { time: "11:30", color: "bg-mainColor-500" },
  { time: "12:30", color: "bg-mainColor-500" },
  { time: "13:00", color: "bg-mainColor-500" },
  { time: "14:00", color: "bg-mainColor-500" },
  { time: "15:00", color: "bg-mainColor-500" },
];

const formatDate = (date) => {
  const days = ["Nedeľa", "Pondelok", "Utorok", "Streda", "Štvrtok", "Piatok", "Sobota"];
  const dayName = days[date.getDay()];
  const formattedDate = `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()} ${dayName}`;
  return formattedDate;
};

const CalendarDashboard = () => {
  const [date, setDate] = useState(new Date());

  return (
    <div className="flex bg-background">
      {/* Sidebar */}
      <div className="w-2/4 p-4 bg-backgorund flex flex-col">
        <h2 className="text-lg font-bold mb-4">Moje rezervácie</h2>
        <Calendar onChange={setDate} value={date} />
      </div>

      {/* Schedule */}
      <div className="w-2/4 p-4 overflow-y-auto">
        <h2 className="text-lg font-bold mb-4">Rezervácie pre: {formatDate(date)}</h2>
        <div className="space-y-2">
          {events.map((event, index) => (
            <motion.div
              key={index}
              className={`p-4 rounded-lg text-white ${event.color} shadow-md flex items-center`}
              whileHover={{ scale: 1.05 }}
            >
              <span className="font-bold mr-4">{event.time}</span>
              <span>Scheduled Event</span>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CalendarDashboard;