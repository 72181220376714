import React, { useState } from "react";
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import { RxDotFilled } from "react-icons/rx";
import {  useQuery } from "@tanstack/react-query";
import { GoPencil } from "react-icons/go";
import CoverImageDialog from "../../../components/dialog/userProfileDialog/CoverImageDialog";

const ImageSlider = ({ slides = [] }) => {
  const { data: user } = useQuery({ queryKey: ["user"] });
  const { data: authUser } = useQuery({ queryKey: ["authUser"] });
  const [currentIndex, setCurrentIndex] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const IsMyProfile = authUser?._id === user?._id;

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  return (
    <div className="w-full max-w-[1400px] h-[300px] sm:h-[500px] mx-auto relative group">
      {/* Render background image only if slides array is not empty */}
      {slides.length > 0 ? (
        <div
          style={{ backgroundImage: `url(${slides[currentIndex]?.url || ''})` }}
          className="w-full h-full rounded-2xl bg-center bg-cover duration-500"
        ></div>
      ) : (
        <div className="w-full h-full rounded-2xl bg-center bg-cover flex items-center justify-center text-gray-500">
          No Images Available
        </div>
      )}
      {/* Left Arrow */}
      <div
        className="sm:group-hover:block absolute top-[50%] left-4 transform -translate-y-1/2 text-xl sm:text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer z-10"
        onClick={prevSlide}
      >
        <BsChevronCompactLeft size={30} className="text-mainColor-500" />
      </div>
      {/* Right Arrow */}
      <div
        className=" sm:group-hover:block absolute top-[50%] right-4 transform -translate-y-1/2 text-xl sm:text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer z-10"
        onClick={nextSlide}
      >
        <BsChevronCompactRight size={30} className="text-mainColor-500" />
      </div>
      {/* Dots navigation */}
      
      <div className="absolute bottom-2 left-0 right-0 m-auto flex justify-center items-center z-1">
        {slides.map((_, slideIndex) => (
          <div
            key={slideIndex}
            onClick={() => goToSlide(slideIndex)}
            className={`${
              currentIndex === slideIndex
                ? "text-lg sm:text-4xl text-mainColor-400"
                : "text-sm sm:text-2xl text-gray-400"
            } z-10 cursor-pointer`}
          >
            <RxDotFilled />
          </div>
        ))}
      </div>
      


      {IsMyProfile && (
          <button
          
          className="absolute right-4 bottom-4 p-3 border-2 border-mainColor-500 rounded-full bg-transparent shadow-md transition duration-300 ease-in-out hover:bg-background hover:text-mainColor-500 hover:border-mainColor-500"
            title="Change photo"
            onClick={() => setModalOpen(true)}
          >
            <GoPencil />
          </button>
        )}
      {modalOpen && <CoverImageDialog
          closeModal={() => setModalOpen(false)} 
        />}
    </div>
  );
};

export default ImageSlider;
