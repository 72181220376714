import apiRequest from "../../lib/ApiRequest";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import Feed from "./Feed";


const FeedsLayout = ({ userId }) => {
  
  const {
    data: posts,
    isLoading,
    refetch,
    isRefetching,
  } = useQuery({
    queryKey: ["posts"],
    queryFn: async () => {
      try {
        const res = await apiRequest.get("posts/all");

        if (res.status !== 200) {
          throw new Error("Failed to fetch authenticated user.");
        }

        const data = res.data;
        if (data.error) return null;
        if (!data || typeof data !== "object") {
          throw new Error("Invalid response data format.");
        }

        return data;
      } catch (error) {
        throw error; // Let useQuery handle the error
      }
    },
  });

  useEffect(() => {
    refetch();
  }, [refetch, userId]);

  return (
    <>
     
      {!isLoading && !isRefetching && posts?.length === 0 && (
        <p className="text-center my-4">No posts in this tab. Switch 👻</p>
      )}
      {!isLoading && !isRefetching && posts && (
        <div>
          {posts.map((post) => (
            <Feed key={post?._id} post={post} />
          ))}
        </div>
      )}
    </>
  );
};

export default FeedsLayout;
