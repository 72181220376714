import { useState } from "react";
import { useParams } from "react-router-dom";
import apiRequest from "../../../lib/ApiRequest";
import { useQuery } from "@tanstack/react-query";


const Services = ({ onSelect }) => {
    const { profileId } = useParams();
    const [selectedService, setSelectedService] = useState(null);

    const { data: userServices = [] } = useQuery({
        queryKey: ["userServices", profileId],
        queryFn: async () => {
            const res = await apiRequest.get(`services/${profileId}`);
            return res.data || [];
        },
    });

    const handleServiceClick = (service) => {
        setSelectedService(service);
        onSelect(service); // Posielame vybranú službu vyššie
    };

    return (
        <div className="p-4 mx-auto">
            <h2 className="text-xl font-bold mb-5 text-mainColor-500">Vyber si službu</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
                {userServices.map((service) => (
                    <div
                        key={service._id}
                        onClick={() => handleServiceClick(service)}
                        className={`p-4 border border-mainColor-500 rounded-lg text-left transform transition-transform duration-200 cursor-pointer 
                        ${selectedService?._id === service._id ? "bg-mainColor-500 text-white" : "bg-background hover:scale-105"}`}
                    >
                        <h3 className="text-lg font-bold mb-2">{service.name}</h3>
                        <p className="text-sm mb-1">Dĺžka trvania: {service.duration} min</p>
                        <p className="text-sm">Cena: {service.price} €</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Services;
