import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import FileInput from "../../inputs/FileInput";
import ImagesCropper from "./components/ImagesCropper";
import Button from "../../inputs/Button";

import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import { RxDotFilled } from "react-icons/rx";
import toast from "react-hot-toast";
import apiRequest from "../../../lib/ApiRequest";

const CoverImageDialog = ({ closeModal }) => {
  const [coverImgs, setCoverImgs] = useState([]);
  const [currentPage, setCurrentPage] = useState("choose-img");
  const [coverImgsAfterCrop, setCoverImgsAfterCrop] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const queryClient = useQueryClient();

  console.log(coverImgsAfterCrop);

  const onImageSelected = (selectedImgs) => {
    setCoverImgs(selectedImgs); // Array of selected images
    setCurrentPage("crop-img");
  };

  const onCropDone = (croppedImg, imageIndex) => {
    // Add the cropped image to the final array
    setCoverImgsAfterCrop((prev) => {
      const updated = [...prev];
      updated[imageIndex] = croppedImg;
      return updated;
    });

    // If all images are processed, go to the next step
    if (coverImgsAfterCrop.length === coverImgs.length - 1) {
      setCurrentPage("image-cropped");
    }
  };

  const onCropCancel = () => {
    setCoverImgs([]);
    setCurrentPage("choose-img");
  };

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide
      ? coverImgsAfterCrop.length - 1
      : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide = currentIndex === coverImgsAfterCrop.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  const handleUpload = () => {
    const files = coverImgsAfterCrop.map((base64, index) => {
      const byteString = atob(base64.split(",")[1]);
      const mimeString = base64.split(",")[0].split(":")[1].split(";")[0];

      const arrayBuffer = new ArrayBuffer(byteString.length);
      const uint8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i++) {
        uint8Array[i] = byteString.charCodeAt(i);
      }

      const blob = new Blob([uint8Array], { type: mimeString });
      return new File([blob], `cover-${index + 1}.jpg`, { type: mimeString });
    });

    // Trigger the mutation with the files array
    uploadImagesMutation(files);
  };

  const { mutate: uploadImagesMutation } = useMutation({
    mutationFn: async (files) => {
      const formData = new FormData();

      // Append up to 5 files to the form data
      for (let i = 0; i < files.length && i < 5; i++) {
        formData.append("coverImg", files[i]); // Ensure this matches your backend field name
      }

      const res = await apiRequest.post("/users/updateCoverImg", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return res.data;
    },
    onSuccess: (data) => {
      toast.success(data.message);
      queryClient.invalidateQueries({ queryKey: ["user"] });
      queryClient.invalidateQueries({ queryKey: ["authUser"] });
      closeModal();
    },
    onError: (error) => {
      const message = error.response?.data?.message || "Something went wrong!";
      toast.error(message);
    },
  });

  return (
    <div
      className="relative z-10"
      aria-labelledby="crop-image-dialog"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-mainColor-800/70 bg-opacity-75 transition-all backdrop-blur-sm"></div>
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full justify-center px-2 py-12 text-center">
          <div className="relative w-[95%] sm:w-[80%] min-h-[60vh] rounded-2xl bg-background text-slate-100 text-left shadow-xl transition-all flex items-center justify-center">
            <div className="px-5 py-4">
              <button
                type="button"
                className="rounded-md p-1 inline-flex items-center justify-center text-gray-400 hover:bg-gray-700 focus:outline-none absolute top-2 right-2"
                onClick={closeModal}
              >
                <span className="sr-only">Close menu</span>
                <IoClose />
              </button>
              <div className="">
                {currentPage === "choose-img" ? (
                  <FileInput
                    onImagesSelected={onImageSelected}
                    label="Vyber titulné fotky"
                    multiple={true}
                  />
                ) : currentPage === "crop-img" ? (
                  <ImagesCropper
                    images={coverImgs}
                    onCropDone={onCropDone}
                    onCropCancel={onCropCancel}
                  />
                ) : (
                  <div className="w-full">
                    <div className="max-w-[1400px] h-[500px] w-full m-auto relative group">
                      <div className="w-full h-full flex justify-center items-center">
                        <img
                          src={coverImgsAfterCrop[currentIndex]}
                          alt="Cropped Cover"
                          className="max-w-full max-h-full object-cover rounded-2xl"
                        />
                      </div>
                      {/* Left Arrow */}
                      <div className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-mainColor/20 text-white cursor-pointer">
                        <BsChevronCompactLeft
                          onClick={prevSlide}
                          size={30}
                          className="text-mainColor-500"
                        />
                      </div>
                      {/* Right Arrow */}
                      <div className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-mainColor/20 text-white cursor-pointer">
                        <BsChevronCompactRight
                          onClick={nextSlide}
                          size={30}
                          className="text-mainColor-500"
                        />
                      </div>
                      <div className="flex top-4 justify-center py-2">
                        {coverImgsAfterCrop.map((slide, slideIndex) => (
                          <div
                            key={slideIndex}
                            onClick={() => goToSlide(slideIndex)}
                            className="text-2xl cursor-pointer"
                          >
                            <RxDotFilled />
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="flex items-center justify-center w-[30%]">
                      <Button label="Uložiť" onClick={handleUpload} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="">
          {/* TODO: pridat galeriu starych profiloviek fotiek */}
        </div>
      </div>
    </div>
  );
};

export default CoverImageDialog