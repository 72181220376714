import { IoMdLogOut } from "react-icons/io";
import { FaCog } from "react-icons/fa";
import { RxQuestionMarkCircled } from "react-icons/rx";
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { VscAccount } from "react-icons/vsc";
import apiRequest from "../../../lib/ApiRequest";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
const UserNavbarDialog = ({ toggleDialog }) => {
    
    const { data: authUser } = useQuery({ queryKey: ["authUser"] });
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const { mutate: logOutMutation } = useMutation({
      mutationFn: async () => {
          await apiRequest.post("auth/logout");
          localStorage.clear();
          sessionStorage.clear();
          queryClient.clear();
      },
      onSuccess: () => {
          toast.success("Logout successful!");
          setTimeout(() => {
              window.location.href = "/";
          }, 100);
      },
      onError: () => {
          toast.error("Logout failed. Please try again.");
      },
    });

    const handleProfileClick = () => {
      if (authUser?._id) {
        navigate(`/profile/${authUser._id}`);
        toggleDialog();
      }
    };

    return (
        <div className="fixed top-20 right-5 bg-background border border-mainColor-500 p-6 rounded-lg shadow-lg w-80 text-white z-50 z-999" onClick={toggleDialog}>
                      {/* User Info */}
            <div className="flex flex-col items-center mb-4 cursor-pointer" onClick={handleProfileClick}>
                {authUser?.profileImg ? (
                    <img
                        className="h-12 w-12 rounded-full border-2 border-mainColor-500 mr-4"
                        src={authUser.profileImg}
                        alt="User Avatar"
                    />
                ) : (
                    <VscAccount className="text-2xl text-mainColor-500 mr-4" />
                )}
                <div className="flex flex-col items-center">
                <p className="text-lg font-bold text-mainColor-500">{authUser?.firstName} {authUser?.lastName}</p>
                <p className="text-sm text-primaryText">{authUser?.company[0]?.companyName}</p>
                </div>
            </div>

            {/* Options */}
            <div className="flex flex-col space-y-3">
                <button className="flex items-center space-x-2 p-2 hover:bg-mainColor-500 rounded-lg">
                    <FaCog className="text-xl text-mainColor-500" />
                    <span className="text-primaryText">SettingsAndPrivacy</span>
                </button>
                <button className="flex items-center space-x-2 p-2 hover:bg-mainColor-500 rounded-lg">
                    <RxQuestionMarkCircled className="text-xl text-mainColor-500" />
                    <span className="text-primaryText">HelpAndSupport</span>
                </button>
                <button className="flex items-center space-x-2 p-2 bg-mainColor-200 rounded-lg text-white hover:bg-red-600" onClick={logOutMutation}>
                    <IoMdLogOut className="text-xl text-mainColor-500" />
                    <p className="text-primaryText">LogOut</p>
                </button>
            </div>
        </div>
    );
};

export default UserNavbarDialog;
