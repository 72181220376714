import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import { useParams } from "react-router-dom";
import { format, addMinutes, startOfDay, setHours, setMinutes, isBefore, getDay, isAfter } from "date-fns";
import Button from "../../inputs/Button";
import apiRequest from "../../../lib/ApiRequest";
import { useQuery } from "@tanstack/react-query";

const DEFAULT_INTERVAL = 15;

const BookingCalendar = ({ onDateSelect, onTimeSelect, selectedService }) => {
  const { profileId } = useParams();
  const [interval, setInterval] = useState(DEFAULT_INTERVAL);
  const today = new Date();
  const [weeklyWorkingHours, setWeeklyWorkingHours] = useState({
    0: null, 1: null, 2: null, 3: null, 4: null, 5: null, 6: null
  });

  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState("");

  // Fetch reservation settings
  const { data: reservationSettings } = useQuery({
    queryKey: ["reservationSettings", profileId],
    queryFn: async () => {
      const res = await apiRequest.get(`reservationSettings/${profileId}`);
      return res.data;
    },
  });

  useEffect(() => {
    if (reservationSettings?.timeSlotInterval) {
      setInterval(reservationSettings.timeSlotInterval);
    }
  }, [reservationSettings]);

  const { data: rawWorkingHours = [] } = useQuery({
    queryKey: ["workingHours", profileId],
    queryFn: async () => {
      const res = await apiRequest.get(`appointment/workingHours/${profileId}`);
      return res.data?.workingHours || [];
    },
  });

  const { data: bookedSlots = [] } = useQuery({
    queryKey: ["bookedSlots", selectedDate],
    queryFn: async () => {
      if (!selectedDate) return [];
      const res = await apiRequest.get(`appointment/booked-slots/${profileId}/${format(selectedDate, "yyyy-MM-dd")}`);
      return res.data.bookedTimes || [];
    },
    enabled: !!selectedDate, // Fetch only if date is selected
  });

  useEffect(() => {
    if (rawWorkingHours) {
      const formattedHours = {
        0: null, 1: null, 2: null, 3: null, 4: null, 5: null, 6: null
      };

      rawWorkingHours.forEach(entry => {
        if (entry && entry.day >= 0 && entry.day <= 6) {
          formattedHours[entry.day] = entry.start && entry.end ? { start: entry.start, end: entry.end } : null;
        }
      });

      setWeeklyWorkingHours(formattedHours);
    }
  }, [rawWorkingHours]); // Runs only when working hours change


  const handleDateChange = (date) => {
    if (isValidDate(date)) {
      const formattedDate = format(date, "yyyy-MM-dd");
      setSelectedDate(date);
      onDateSelect(formattedDate);
    }
  };

  const handleTimeChange = (time) => {
    setSelectedTime(time);
    onTimeSelect(time);
  };

  const isValidDate = (date) => {
    const dayOfWeek = getDay(date);
    if (weeklyWorkingHours[dayOfWeek] === null) return false;
    if (format(date, "yyyy-MM-dd") === format(today, "yyyy-MM-dd")) {
      const currentTime = new Date();
      const [endHour, endMinute] = weeklyWorkingHours[dayOfWeek].end.split(":").map(Number);
      const closingTime = setMinutes(setHours(startOfDay(date), endHour), endMinute);
      return isBefore(currentTime, closingTime);
    }
    return isAfter(date, today);
  };



  const generateTimeSlots = (date, serviceDuration = interval) => {
    if (!date) return [];
    const workingHours = weeklyWorkingHours[getDay(date)];
    if (!workingHours || !workingHours.start || !workingHours.end) return [];

    const [startHour, startMinute] = workingHours.start.split(":").map(Number);
    const [endHour, endMinute] = workingHours.end.split(":").map(Number);

    let timeSlots = [];
    let currentTime = setMinutes(setHours(startOfDay(date), startHour), startMinute);
    const endTime = setMinutes(setHours(startOfDay(date), endHour), endMinute);

    while (isBefore(currentTime, endTime)) {
      const timeStr = format(currentTime, "HH:mm");

      // Kontrola, či celý interval služby je voľný
      let isSlotAvailable = true;
      let checkTime = currentTime;

      for (let i = 0; i < serviceDuration; i += interval) {
        const checkTimeStr = format(checkTime, "HH:mm");
        if (bookedSlots.includes(checkTimeStr)) {
          isSlotAvailable = false;
          break;
        }
        checkTime = addMinutes(checkTime, interval);
      }

      if (isSlotAvailable && isBefore(addMinutes(currentTime, serviceDuration), endTime)) {
        timeSlots.push(timeStr);
      }

      currentTime = addMinutes(currentTime, interval);
    }

    return timeSlots;
  };


  return (
    <div className="p-4 mx-auto">
      <h2 className="text-xl font-bold mb-4">Vyberte dátum</h2>
      <Calendar onChange={handleDateChange} value={selectedDate} tileDisabled={({ date }) => !isValidDate(date)} />

      {selectedDate && (
        <div className="mt-4">
          <h3 className="text-lg font-semibold">Dostupné časy pre {format(selectedDate, "dd.MM.yyyy")}:</h3>
          <div className="grid grid-cols-3 gap-2 mt-2">
            {generateTimeSlots(selectedDate, selectedService?.duration || interval).map((time) => (
              <Button key={time} className="p-2" label={time} onClick={() => handleTimeChange(time)} outline={selectedTime !== time} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default BookingCalendar;
