import React, { useRef } from "react";
import Button from "../inputs/Button";

const FileInput = ({ onImagesSelected, onImageSelected, label, multiple = false, ...props }) => {
  const inputRef = useRef();

  const handleOnChange = (event) => {
    const files = Array.from(event.target.files);
    if (multiple && onImagesSelected) {
      const readers = files.map((file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      });

      Promise.all(readers)
        .then((base64Images) => {
          onImagesSelected(base64Images);
        })
        .catch((error) => console.error("Error reading files:", error));
    } else if (!multiple && onImageSelected && files[0]) {
      const reader = new FileReader();
      reader.onload = () => onImageSelected(reader.result);
      reader.readAsDataURL(files[0]);
    }
  };

  const onChooseImg = () => {
    inputRef.current.click();
  };

  return (
    <div>
      <input
        {...props}
        className="hidden"
        type="file"
        accept="image/png, image/jpeg, image/webp"
        ref={inputRef}
        onChange={handleOnChange}
        multiple={multiple}
      />
      <Button label={label} onClick={onChooseImg} />
    </div>
  );
};


export default FileInput;
