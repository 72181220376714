import React from 'react'
import RightMenu from "../../components/rightMenu/RightMenu";
import LeftMenu from "../../components/leftMenu/LeftMenu";
import FeedsLayout from '../../components/feeds/FeedsLayout';

const Home = () => {
  return (
    <div className="flex gap-6 pt-28">
      {/* Left */}
      <div className="hidden xl:block w-[25%]">
        <LeftMenu type="home" />
      </div>
      <div className="w-full lg:w-[50%]">
        <div className="flex flex-col gap-6">
          <FeedsLayout />
        </div>
      </div>
      {/* Right */}
      <div className="hidden lg:block w-[25%]">
        <RightMenu />
      </div>
    </div>
  );
};

export default Home