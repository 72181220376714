import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import apiRequest from "../lib/ApiRequest";

const useFollow = () => {
    const queryClient = useQueryClient();

    const { mutate: follow, isLoading } = useMutation({
        mutationFn: async (userId) => {
            try {
                const res = await apiRequest.post(`users/follow/${userId}`);
                return res.data; // Return response data for further processing
            } catch (error) {
                console.error("Error in follow/unfollow mutation:", error.message);
                throw error; // Ensure error propagates to onError handler
            }
        },
        onSuccess: (data, userId) => {
            // Invalidate queries to refetch relevant data
            // queryClient.invalidateQueries(["user", userId]); // Refresh target user's data
            queryClient.invalidateQueries(["authUser"]); // Refresh authenticated user

            toast.success(data.message);
        },
        onError: (error) => {
            toast.error(error.response?.data?.error || "Something went wrong");
        },
    });

    return { follow, isLoading };
};

export default useFollow;
