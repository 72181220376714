import ImageSlider from "./ImageSlider.js";
import ProfileImage from "./ProfileImage.js";
import EditableInput from "../../../components/inputs/EditableInput.js";
import apiRequest from "../../../lib/ApiRequest.js";
import toast from "react-hot-toast";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import useFollow from "../../../hooks/useFollow.js";
import { BsFillPostcardFill } from "react-icons/bs";  // Post icon
import { IoCalendarOutline } from "react-icons/io5"; // Calendar icon
import { RiStarHalfFill } from "react-icons/ri";      // Review icon
import { TbEyeMinus, TbEyePlus } from "react-icons/tb";
import Bio from "./Bio/Bio.js";

const ProfileHeader = ({ selectedTab, onTabChange }) => {
  const { data: user } = useQuery({ queryKey: ["user"] });
  const { data: authUser } = useQuery({ queryKey: ["authUser"] });
  const queryClient = useQueryClient();

  const { follow } = useFollow();

  const { mutate: updateUser } = useMutation({
    mutationFn: async ({ data }) => {
      try {
        const res = await apiRequest.post("users/update", { data });
        if (res.status !== 200) throw new Error("Failed to update USER.");
        return res.data;
      } catch (error) {
        console.error("Error during updateBIO mutation:", error.message);
        throw error;
      }
    },
    onSuccess: (data) => {
      toast.success(data.message);
      queryClient.invalidateQueries({ queryKey: ["user"] });
    },
    onError: () => {
      toast.error("Chyba pri aktualizácii používateľa. Skúste to znova.");
    },
  });

  const handleSaveField = (field, value) => {
    if (user?.[field] !== value) {
      updateUser({ data: { [field]: value } }); // Send update request
    }
  };

  const displayName = user?.isSupplier ? (
    <span className="font-bold text-mainColor-500">
      {user?.company?.[0]?.companyName}
    </span>
  ) : (
    <span>{`${user?.firstName} ${user?.lastName}`}</span>
  );
  const amIFollowing = authUser?.following.includes(user?._id);
  const IsMyProfile = authUser?._id === user?._id;


  return (
    <div className="w-full mt-24 md:px-12 lg:px-16 xl:px-72 shadow-lg bg-background">
      {/* Cover Image Slider */}
      <ImageSlider slides={user?.coverImg?.map((img) => ({ url: img })) || []} />

      <div className="w-full flex flex-col md:flex-row md:items-start md:justify-between mt-6 space-y-4 md:space-y-0 px-4">

        {/* Profile Image & User Info */}
        <div className="flex flex-col md:flex-row items-center md:items-start gap-0 lg:gap-4 w-full md:w-auto">
          <ProfileImage />

          <div className="flex flex-col items-center md:items-start mt-0 md:mt-0 px-4">
            <h1 className="text-3xl md:text-4xl font-bold text-mainColor-500 text-center md:text-left">
              {displayName}
            </h1>

            <p className="text-primaryText text-center md:text-left">
              {user?.followers?.length || 0} sledovatelia
            </p>

            <p className="text-primaryText text-center md:text-left">
              {user?.following?.length || 0} sledované
            </p>
          </div>
        </div>

        {/* Follow Button (Centered on Mobile, Left on Desktop) */}
        {!IsMyProfile &&
        <div className="w-full md:w-auto flex justify-center md:justify-start">
          <button
            className={`flex items-center justify-center gap-2 px-5 py-2 rounded-full border-2 text-lg transition-all duration-300 shadow-md hover:scale-105 ${amIFollowing ? "bg-red-500 text-white border-red-600" : "bg-mainColor-500 text-white border-mainColor-600"
              }`}
            onClick={() => follow(user?._id)}
          >
            {amIFollowing ? (
              <>
                <TbEyeMinus className="text-xl" />
                <span className="hidden md:inline">Nesledovať</span> {/* Show text on large screens only */}
              </>
            ) : (
              <>
                <TbEyePlus className="text-xl" />
                <span className="hidden md:inline">Sledovať</span> {/* Show text on large screens only */}
              </>
            )}
          </button>
        </div>
}
    </div>
    


      {/* User Bio */}
      {user?.isSupplier && (
      <Bio bio={user?.bio} IsMyProfile={IsMyProfile} />
      )}

      {/* Editable User Information */}
      <div className="relative">
        <div className="flex flex-col">
          <div className="gap-4 px-6 mt-6">
            <h2 className="text-xl text-mainColor-500 font-semibold">Kontakt</h2>

            {user?.isSupplier && (
              <p className="text-primaryText">
                <strong>Zodpovedná osoba:</strong> {user?.firstName} {user?.lastName}
              </p>
            )}

            {/* Editable Inputs for Contact Info */}
            <EditableInput
              label="Email"
              value={user?.emailContact}
              IsMyProfile={IsMyProfile}
              onSave={(value) => handleSaveField("emailContact", value)}
            />
            <EditableInput
              label="Telefón"
              value={user?.phoneNumber}
              IsMyProfile={IsMyProfile}
              onSave={(value) => handleSaveField("phoneNumber", value)}
            />
            {user?.isSupplier && (
              <EditableInput
                label="Web"
                value={user?.link}
                IsMyProfile={IsMyProfile}
                onSave={(value) => handleSaveField("link", value)}
              />
            )}
          </div>

          {/* Editable Inputs for Business Info */}
          {user?.isSupplier && (
            <div className="gap-4 px-6">
              <EditableInput
                label="Oblasť podnikania"
                usefull
                value={user?.businessArea}
                IsMyProfile={IsMyProfile}
                onSave={(value) => handleSaveField("businessArea", value)}
              />
              <EditableInput
                label="Oblasť pôsobenia"
                usefull
                value={user?.operationArea}
                IsMyProfile={IsMyProfile}
                onSave={(value) => handleSaveField("operationArea", value)}
              />
            </div>
          )}
        </div>
      </div>

      {/* Profile Tabs */}
      <div className="border-t mt-6 w-full">
        <div className="flex items-center justify-center gap-4 w-full px-4 pt-4">

          {user?.isSupplier && (
            <button
              className={`w-full md:w-auto flex items-center justify-center gap-2 px-4 py-2 rounded-t-xl border-2 text-xl ${selectedTab === "posts"
                ? "bg-mainColor-500 text-white"
                : "text-mainColor-500 border-mainColor-500"
                }`}
              onClick={() => onTabChange("posts")}
            >
              <BsFillPostcardFill className="text-2xl" />
              <span className="hidden md:block">Príspevky</span>
            </button>
          )}

          <button
            className={`w-full md:w-auto flex items-center justify-center gap-2 px-4 py-2 rounded-t-xl border-2 text-xl ${selectedTab === "calendar"
              ? "bg-mainColor-500 text-white"
              : "text-mainColor-500 border-mainColor-500"
              }`}
            onClick={() => onTabChange("calendar")}
          >
            <IoCalendarOutline className="text-2xl" />
            <span className="hidden md:block">Rezervácie</span>
          </button>

          <button
            className={`w-full md:w-auto flex items-center justify-center gap-2 px-4 py-2 rounded-t-xl border-2 text-xl ${selectedTab === "ratings"
              ? "bg-mainColor-500 text-white"
              : "text-mainColor-500 border-mainColor-500"
              }`}
            onClick={() => onTabChange("ratings")}
          >
            <RiStarHalfFill className="text-2xl" />
            <span className="hidden md:block">Hodnotenia</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfileHeader;
