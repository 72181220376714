import { useState } from "react";

export default function useHeader() {
  const [modalOpen, setModalOpen] = useState(true);
  const [sidenavOpen, setSidenavOpen] = useState(false);
  const [userMenuOpen, setUserMenuOpen] = useState(false);

  const toggleModal = () => setModalOpen((state) => !state);
  const toggleSidenav = () => setSidenavOpen((state) => !state);
  const toggleUserMenu = () => setUserMenuOpen((state) => !state);

  return {
    modalOpen,
    sidenavOpen,
    userMenuOpen,
    toggleModal,
    toggleSidenav,
    toggleUserMenu,
  };
}
