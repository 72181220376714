import React, { useEffect, useState } from "react";
import Header from "../auth/components/Header";
import Register from "../auth/register/Register";
import Login from "../auth/login/Login";
import ResetPassword from "./resetPassword/ResetPassword";
import VerifyEmail from "../../pages/VerifyEmail";
import ResetPasswordView from "./components/ResetPasswordView";
import { useParams } from "react-router-dom";

const AuthWrapper = ({ modalOpen, toggleModal, setIsAuthOpen }) => {
    const [view, setView] = useState("login"); // State to manage the current view
    const { token } = useParams(); // Capture token from URL
  
  // Handle login or registration success (close modal and redirect)
  const handleLoginSuccess = () => {
    toggleModal(false); // Close the modal
    window.location.href = "/"; // Redirect to the home page or any other route
  };

    const switchToRegister = () => setView("register");
    const switchToLogin = () => setView("login");
    const switchToResetPassword = () => setView("reset-password");
    const switchToEmailVerification = () => setView("verify-email");
    
    useEffect(() => {
      if (token) {
        setView("reset-password-view"); // Automatically switch to reset-password-view
      }
    }, [token]);

    if (!modalOpen) return null;
  
    return (
      <div
        className="relative z-10"
        aria-labelledby="auth-dialog"
        role="dialog"
        aria-modal="true"
      >
      <div className="fixed inset-0 bg-mainColor-800/70 bg-opacity-75 transition-all backdrop-blur-sm">
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex justify-center px-2 py-12">
          <div
            className={`transition-all duration-300 transform ${
                modalOpen
                ? "translate-y-0 opacity-100"
                : "translate-y-full opacity-0"
            } bg-background shadow-lg rounded-lg`}
          >
            {/* Modal Header */}
            <div className="p-6">
              <Header />
            </div>
  
            {/* Modal Content */}
            <div className="p-10">
              {view === "login" && <Login closeDialog={handleLoginSuccess} verifyEmail={switchToEmailVerification}/>}
              {view === "register" && (
                <Register
                  closeDialog={switchToEmailVerification} // Correctly passing the function
                  toggleModal={toggleModal}
                />
              )}
              {view === "reset-password" && <ResetPassword />}
              {view === "verify-email" && (
                <VerifyEmail switchToLogin={switchToLogin} />
              )}
              {view === "reset-password-view" && (
                <ResetPasswordView
                  switchToLogin={switchToLogin}
                />
              )}
            </div>
  
            {/* Modal Footer */}
            <div className="flex flex-col gap-2 p-6">
              {view === "login" && (
                <>
                  <p className="text-center text-sm">
                    Nemáš ešte účet?
                    <button
                      onClick={switchToRegister}
                      className="text-mainColor-500 hover:underline ml-1"
                    >
                      Zaregistruj sa
                    </button>
                  </p>
                  <p className="text-center text-sm">
                    Zabudol si heslo?
                    <button
                      onClick={switchToResetPassword}
                      className="text-mainColor-500 hover:underline ml-1"
                    >
                      Resetovať heslo
                    </button>
                  </p>
                </>
              )}
  
              {view === "register" && (
                <p className="text-center text-sm">
                  Máš už účet?
                  <button
                    onClick={switchToLogin}
                    className="text-mainColor-500 hover:underline ml-1"
                  >
                    Prihlás sa
                  </button>
                </p>
              )}
  
              {view === "reset-password" && (
                <>
                  <p className="text-center text-sm">
                    Spomenul si si na heslo?
                    <button
                      onClick={switchToLogin}
                      className="text-mainColor-500 hover:underline ml-1"
                    >
                      Prihlás sa
                    </button>
                  </p>
                  <p className="text-center text-sm">
                    Nemáš ešte účet?
                    <button
                      onClick={switchToRegister}
                      className="text-mainColor-500 hover:underline ml-1"
                    >
                      Zaregistruj sa
                    </button>
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      </div>
      </div>
    );
  };
export default AuthWrapper