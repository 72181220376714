import React, { useEffect, useRef, useState } from "react";
import { FaRegHeart, FaHeart } from "react-icons/fa6";
import { IoIosMore } from "react-icons/io";
import { FaRegComment } from "react-icons/fa";
import { RiShareForwardLine } from "react-icons/ri";
import { formatRelativeTime } from "../../utils/timeFormatter";
import { Link } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import apiRequest from "../../lib/ApiRequest";
import LoadingSpinner from "../LoadingSpinner";
import { VscAccount } from "react-icons/vsc";
import CommentList from "./components/CommentList";

const Feed = ({ post }) => {
    const [comment, setComment] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const { data: authUser } = useQuery({ queryKey: ["authUser"] });
    const menuRef = useRef(null);
    const queryClient = useQueryClient();

    const postOwner = post?.user;
    const isLiked = post?.likes?.includes(authUser?._id);
    const isMyProfile = authUser?._id === post.user?._id;

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const { mutate: likePost, isPending: isLiking } = useMutation({
        mutationFn: async () => {
            const res = await apiRequest.post(`/posts/like/${post._id}`);
            return res.data;
        },
        onSuccess: (updatedLikes) => {
            queryClient.setQueryData(["posts"], (oldData) => {
                return oldData.map((p) => (p._id === post._id ? { ...p, likes: updatedLikes } : p));
            });
        },
        onError: (error) => {
            toast.error(error.response?.data?.message || "Something went wrong!");
        },
    });

    const { mutate: deletePost } = useMutation({
        mutationFn: async () => {
            const res = await apiRequest.delete(`/posts/${post._id}`);
            return res.data;
        },
        onSuccess: () => {
            toast("Post deleted successfully!");
            queryClient.invalidateQueries({ queryKey: ["posts"] });
        },
        onError: (error) => {
            toast.error(error.response?.data?.error || "Failed to delete post!");
        },
    });

    const { mutate: commentPost } = useMutation({
        mutationFn: async ({ text }) => {
            const res = await apiRequest.post(`/posts/comment/${post._id}`, { text });
            return res.data;
        },
        onSuccess: (updatedPost) => {
            queryClient.setQueryData(["posts"], (oldData) => {
                return oldData.map((p) => (p._id === post._id ? updatedPost : p));
            });
            toast.success("Comment added successfully!");
            setComment(""); // Reset input after success
        },
        onError: (error) => {
            toast.error(error.message || "Failed to add comment.");
        },
    });

    const handlePostComment = (e) => {
        e.preventDefault();
        if (!comment.trim()) return;
        commentPost({ text: comment }); // ✅ Pass the comment text
    };

    return (
        <div className="bg-background text-gray-300 flex flex-col p-4 my-4 gap-4 rounded-lg shadow-md border border-mainColor-500">
            {/* User Info */}
            <div className="relative flex items-center justify-between border-b border-mainColor pb-3">
                <Link to={`/profile/${postOwner?._id}`} className="flex items-center gap-4">
                    {post.user.profileImg ? (
                        <img
                            src={post?.user?.profileImg}
                            alt="User Avatar"
                            className="w-10 h-10 rounded-full border-2 border-mainColor"
                        />
                    ) : (
                        <VscAccount className="text-2xl text-mainColor-500" />
                    )}
                    <div>
                        <h3 className="text-mainColor font-semibold">{postOwner?.firstName} {postOwner?.lastName}</h3>
                        <span className="text-sm text-gray-400">{formatRelativeTime(post?.createdAt)}</span>
                    </div>
                </Link>
                {isMyProfile && <IoIosMore className="text-2xl text-mainColor cursor-pointer" onClick={() => setIsOpen(!isOpen)} />}
                {isOpen && (
                    <div ref={menuRef} className="absolute right-0 mt-2 w-64 bg-background border border-mainColor-500 shadow-lg rounded-lg p-2 z-50">
                        <button className="w-full text-left p-2 hover:bg-gray-100 flex items-center gap-2" onClick={() => deletePost()}>
                            ❌ Zmazať príspevok
                        </button>
                    </div>
                )}
            </div>

            {/* Image */}
            {post?.img && <img src={post.img} alt="Post content" className="w-full rounded-lg" />}

            {/* Actions */}
            <div className="flex items-center justify-start gap-4 mt-4 text-mainColor">
                <div className="flex gap-1 items-center group cursor-pointer" onClick={() => likePost()}>
                    {isLiking && <LoadingSpinner size="sm" />}
                    {!isLiked && !isLiking && <FaRegHeart className="text-xl cursor-pointer hover:text-red-500" />}
                    {isLiked && !isLiking && <FaHeart className="text-xl cursor-pointer hover:text-red-500" />}
                </div>
                <FaRegComment className="text-xl cursor-pointer" />
                <RiShareForwardLine className="text-xl cursor-pointer" />
            </div>

            {/* Likes Count */}
            <div className="mt-2 text-gray-400 text-sm">{post?.likes?.length || 0} Likes</div>

            {/* Post Content */}
            <div className="mt-2 text-sm leading-relaxed">
                <p>
                    <span className="font-semibold text-mainColor">{postOwner?.firstName} {postOwner?.lastName} </span>
                    {isExpanded ? post?.text : post?.text?.slice(0, 100) + "..."}
                    {post?.text?.length > 100 && (
                        <span className="text-orange-500 cursor-pointer ml-2 hover:underline" onClick={() => setIsExpanded(!isExpanded)}>
                            {isExpanded ? " zobraziť menej" : " viac"}
                        </span>
                    )}
                </p>
            </div>

            {/* Comment Section */}
            <form onSubmit={handlePostComment} className="mt-4 flex gap-2">
                <input
                    type="text"
                    placeholder="Pridať komentár..."
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    className="w-full p-2 rounded-full border border-mainColor bg-transparent text-white placeholder-gray-500 focus:outline-none"
                />
                <button type="submit" className="btn btn-primary rounded-full btn-sm text-white px-4">Post</button>
            </form>
            <CommentList comments={post.comments} />

                        
    
        </div>
    );
};

export default Feed;
