import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import apiRequest from '../../../lib/ApiRequest';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';

const CalendarSettings = () => {
  const { profileId } = useParams();
  const queryClient = useQueryClient();

  const [settings, setSettings] = useState({
    minHoursBeforeBooking: "",
    maxHoursBeforeCancellation: "",
    maxDaysInAdvance: "",
    timeSlotInterval: "",
  });

  const { data: calendarSettings } = useQuery({
    queryKey: ["calendarSettings", profileId],
    queryFn: async () => {
      const res = await apiRequest.get(`reservationSettings/${profileId}`);
      return res.data || {};
    },
    enabled: !!profileId,
    onSuccess: (data) => {
      setSettings(data);
    },
  });

  const { mutate: updateCalendarSettings, isLoading } = useMutation({
    mutationFn: async (newSettings) => {
      console.log("Payload odoslaný na server:", newSettings);
      const res = await apiRequest.put(`reservationSettings/${profileId}`, newSettings);
      return res.data;
    },
    onSuccess: () => {
      toast.success("Nastavenia kalendára boli úspešne aktualizované!");
      queryClient.invalidateQueries({ queryKey: ["calendarSettings", profileId] });
    },
    onError: () => {
      toast.error("Nepodarilo sa aktualizovať nastavenia kalendára.");
    },
  });

  useEffect(() => {
    if (calendarSettings) {
      setSettings((prev) => ({
        ...prev,
        ...calendarSettings,
      }));
    }
  }, [calendarSettings]);
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSettings((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateCalendarSettings(settings);
  };

  return (
    <div className="bg-white w-full rounded-lg shadow-lg p-6">
      <div className="flex items-center justify-between mb-6">
        <p className="text-lg font-bold text-mainColor-500">Nastavenie rezervácii</p>
        <p className="text-sm text-gray-600">3 / 3</p>
      </div>
      <div className="w-full bg-gray-200 rounded-full h-2 mb-4">
        <div className="bg-mainColor-500 h-2 rounded-full w-3/3"></div>
      </div>

      <div className="bg-mainColor-100 p-4 rounded-lg border border-mainColor-300 mb-6">
        <p className="text-sm text-mainColor-700 font-medium">
          Nastavenie rezervácii. Ak si nie ste istý môžete sa k tomuto kroku vratit neskor v sekcii <strong> nastavenia -> Nastavenia rezervacii</strong>.
        </p>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Minimálny počet hodín pred uskutočnením rezervácie:</label>
            <input
              type="number"
              name="minHoursBeforeBooking"
              value={settings.minHoursBeforeBooking ?? ""}
              onChange={handleInputChange}
              className="mt-1 block w-full border border-mainColor-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
              placeholder="0"
            />
            <span className='text-xs'> <strong>Príklad:</strong> Ak je práve 10:30 a máš zvolené 3 hodiny pred uskutočnením rezervacie, zákazník bude mat najbližši voľný termin o 13:30 </span>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Počet hodín pred začiatkom rezervácie, kedy môže zákazník najneskôr rezerváciu zrušiť:</label>
            <input
              type="number"
              name="maxHoursBeforeCancellation"
              value={settings.maxHoursBeforeCancellation ?? ""}
              onChange={handleInputChange}
              className="mt-1 block w-full border border-mainColor-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
              placeholder="0"
            />
            <span className='text-xs'> <strong>Príklad:</strong> Ak je aktuálny čas 14:30 a zadáme 4 hodiny, zákazník môže zrušiť rezerváciu najneskôr do 10:30</span>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Maximálny počet dní ponúknutých verejnosti v rezervačnom formulári:</label>
            <input
              type="number"
              name="maxDaysInAdvance"
              value={settings.maxDaysInAdvance ?? ""}
              onChange={handleInputChange}
              className="mt-1 block w-full border border-mainColor-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
              placeholder="100"
            />
            <span className='text-xs'> <strong>Príklad:</strong> Ak zadáme 30 dní, zákazníci si budú môcť rezervovať termín iba na najbližších 30 dní.</span>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Interval počiatočných časov služieb:</label>
            <input
              type="number"
              name="timeSlotInterval"
              value={settings.timeSlotInterval ?? ""}
              onChange={handleInputChange}
              className="mt-1 block w-full border border-mainColor-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
              placeholder='15'
            />
            <div className='flex flex-col'>
              <span className='text-xs'> <strong>Príklad1:</strong> Ak zadáme 10 minút, budú dostupné časy ako 08:00, 08:10, 08:20</span>
              <span className='text-xs'> <strong>Príklad2:</strong> Ak zadáme 30 minút, budú dostupné časy ako 08:00, 08:30, 09:00</span>
            </div>
          </div>
        </div>

        <div className="flex justify-end mt-6">
          <button
            type="submit"
            disabled={isLoading}
            className="bg-green-500 text-white font-bold py-2 px-6 rounded-lg hover:bg-green-600 focus:ring-2 focus:ring-green-400 focus:outline-none"
          >
            {isLoading ? "Ukladá sa..." : "Uložiť"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CalendarSettings;
