import React, { useState } from "react";
import Cropper from "react-easy-crop";
import Button from "../../../inputs/Button";

const MultiImageCropper = ({ images, onCropDone, onCancel }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState(null);
  const aspectRatio = 16 / 9;

  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  const handleSaveCrop = () => {
    if (croppedArea) {
      const canvas = document.createElement("canvas");
      canvas.width = croppedArea.width;
      canvas.height = croppedArea.height;

      const ctx = canvas.getContext("2d");
      const img = new Image();
      img.src = images[currentImageIndex];
      img.onload = () => {
        ctx.drawImage(
          img,
          croppedArea.x,
          croppedArea.y,
          croppedArea.width,
          croppedArea.height,
          0,
          0,
          croppedArea.width,
          croppedArea.height
        );
        const croppedImg = canvas.toDataURL("image/jpeg");
        onCropDone(croppedImg, currentImageIndex);

        // Move to next image or finish
        if (currentImageIndex < images.length - 1) {
          setCurrentImageIndex((prev) => prev + 1);
          setCrop({ x: 0, y: 0 });
          setZoom(1);
        }
      };
    }
  };

  return (
    <div className="relative w-[80vw] h-[90vh] flex items-end justify-center rounded-lg overflow-hidden">
      <div className="">
        <Cropper
          image={images[currentImageIndex]}
          aspect={aspectRatio}
          crop={crop}
          zoom={zoom}
          onCropChange={setCrop}
          onZoomChange={setZoom}
          onCropComplete={onCropComplete}
          showGrid={false}
          
          style={{
            containerStyle: {
              height: "50%",
              margin: "20px",
              backgroundColor: "#fff",
            },
          }}
        />
      </div>

      <div className="flex items-center justify-center gap-2">
        <Button
          label="Zrušiť"
          onClick={onCancel}
          outline
        />
        <Button
          label={currentImageIndex < images.length - 1 ? "Ďalej" : "Orezať a uložiť"}
          onClick={handleSaveCrop}
        />
      </div>
    </div>
  );
};

export default MultiImageCropper;
