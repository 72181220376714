import { useState } from "react";
import toast from "react-hot-toast";
import Input from "../../inputs/Input";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { PasswordResetValidationSchema } from "../register/components/validation";
import { Formik } from "formik";
import Button from "../../inputs/Button";
import { useMutation } from "@tanstack/react-query";
import apiRequest from "../../../lib/ApiRequest";
import { useNavigate, useParams } from "react-router-dom";

const ResetPasswordView = ({ switchToLogin }) => {
  const [visible, setVisible] = useState(false);
  const [msg, setMsg] = useState();
  const [errorMsg, setErrorMsg] = useState(null);
  const [initialValues] = useState({
     password: "",
     re_password:"",
  });
const {id,token} = useParams()
const navigate = useNavigate();

  const { mutate: passwordResetMutation, isPending } = useMutation({
    mutationFn: async ({ email, password }) => {
      try {
        const res = await apiRequest.post(`auth/reset-password/${id}/${token}`, { id,token,password });

        if (res.status !== 200) {
          throw new Error("Failed to resetPassword. Please check your credentials.");
        }

        const data = res.data;
        if (!data || typeof data !== "object") {
          throw new Error("Invalid response data format.");
        }

        return data; // Return data for onSuccess
      } catch (error) {
        console.error("Error during resetPassword mutation:", error.message);
        throw error; // Let useMutation handle the error
      }
    },
    onSuccess: (data) => {
      toast.success("Password reset successfully, redirecting to login page...");
      setMsg(data.message)
      setTimeout(() => {
				navigate("/");
        switchToLogin()

			}, 2000);
    },
    onError: (error) => {
      const message = error.response?.data?.message || "Something went wrong!";
      setErrorMsg(message);
      toast.error(message || "An error occurred during login.");
    },
    retry: false,
  });

  // handleSubmit gets values and formikBag from Formik
  const handleSubmit = (values) => {
    passwordResetMutation(values); // Pass form values to mutation
    
  };

  const toggleVisibility = () => {
    setVisible(!visible);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={PasswordResetValidationSchema}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col gap-4" key="reset-password-view">
            <Input
              name="password"
              label="Zadaj nové heslo"
              type={visible ? "text" : "password"}
              rightIcon={visible ? MdVisibility : MdVisibilityOff}
              onIconClick={toggleVisibility}
            />
             <Input
                name="re_password"
                label="Zopakuj heslo"
                type={visible ? "text" : "password"}
                rightIcon={visible ? MdVisibility : MdVisibilityOff}
                onIconClick={toggleVisibility}
              />
            <Button type="submit" label={isPending ? "Loading..." : "Zmeniť heslo"} />
            {errorMsg && <p className="text-sm text-red-600">{errorMsg}</p>}
            {msg && <p className="text-sm text-green-600">{msg}</p>}
          </div>
        </form>
      )}
    </Formik>
  );
};

export default ResetPasswordView;
