import React, { useState } from "react";
import Button from "../../inputs/Button";
import { toast } from "react-hot-toast";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import apiRequest from "../../../lib/ApiRequest";
import Input from "../../inputs/Input";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { Formik } from "formik";
import { LoginValidationSchema } from "../register/components/validation";

const Login = ({verifyEmail}) => {
  const [visible, setVisible] = useState(false);
  const [msg, setMsg] = useState();
  const [errorMsg, setErrorMsg] = useState(null);
  const queryClient = useQueryClient();
  const [initialValues] = useState({
    email: "",
    password: "",
  });

  // Using react-query's useMutation for handling login
  const { mutate: loginMutation, isPending } = useMutation({
    mutationFn: async ({ email, password }) => {
      try {
        const res = await apiRequest.post("auth/login", { email, password });

        if (res.status !== 200) {
          throw new Error("Failed to login. Please check your credentials.");
        }

        const data = res.data;
        if (!data || typeof data !== "object") {
          throw new Error("Invalid response data format.");
        }

        return data; // Return data for onSuccess
      } catch (error) {
        console.error("Error during login mutation:", error.message);
        throw error; // Let useMutation handle the error
      }
    },
    onSuccess: (data) => {
      toast.success("Login successful!");
      setMsg(data.message);
      queryClient.invalidateQueries({ queryKey: ["authUser"] });
    },
    onError: (error) => {
      const message = error.response?.data?.message || "Something went wrong!";
      setErrorMsg(message);
      toast.error(message || "An error occurred during login.");
      if (message === "Email nie je overený. Bol zaslaný nový overovací email.") {
        verifyEmail();
      }
    },
    retry: false,
  });

  // handleSubmit gets values and formikBag from Formik
  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(true); // Mark the form as submitting
    loginMutation(values); // Pass form values to mutation
    setSubmitting(false); // Reset submitting state
  };

  const toggleVisibility = () => {
    setVisible(!visible);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={LoginValidationSchema}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} id="login">
          <div className="flex flex-col gap-4" key="login">
            <Input name="email" label="Email"  id="username" />

            <Input
              name="password"
              label="Heslo"
              autoComplete="current-password"
              type={visible ? "text" : "password"}
              rightIcon={visible ? MdVisibility : MdVisibilityOff}
              onIconClick={toggleVisibility}
            />
            <Button type="submit" label={isPending ? "Loading..." : "Login"} />
            {errorMsg && <p className="text-sm text-red-600">{errorMsg}</p>}
            {msg && <p className="text-sm text-green-600">{msg}</p>}
          </div>
        </form>
      )}
    </Formik>
  );
};

export default Login;
