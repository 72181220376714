import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
// import "./i18n.js";
import "./styles/calendar.css"
import DashBoard from './pages/DashBoard.js';
import NotFoundPage from './pages/NotFoundPage.js';
import Home from './pages/Home/Home.js';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import ProfileLayout from './pages/ProfilePage/ProfileLayout.js';
import ProfilePage from './pages/ProfilePage/ProfilePage.js';
import { persistQueryClient } from '@tanstack/react-query-persist-client';
import AuthWrapper from './components/auth/AuthWrapper.js';
import PostDetailPage from './pages/ProfilePage/components/PostDetailPage.js';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
});

const persister = {
    persistClient: (client) => {
        window.localStorage.setItem('react-query-cache', JSON.stringify(client));
    },
    restoreClient: async () => {
        const cache = window.localStorage.getItem('react-query-cache');
        return cache ? JSON.parse(cache) : undefined;
    },
    removeClient: async () => {
        window.localStorage.removeItem('react-query-cache');
    }
};

persistQueryClient({
    queryClient,
    persister,
    maxAge: 1000 * 60 * 60 * 24, // Keep cache for 24 hours
});

// Define Routes
const router = createBrowserRouter([
  {
    path: "/",
    element: <DashBoard />,
    errorElement: <NotFoundPage />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/profile",
        element: <ProfileLayout />,
        children: [
          {
            path: "/profile/:profileId", element: <ProfilePage />,
          }
        ],
      },
      { path: "/reset-password/:id/:token", element: <AuthWrapper /> },
      {
        path: "/post/:postId",  // <-- New Post Detail Route
        element: <PostDetailPage />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>
);
