import React, { useState } from 'react';
import Cropper from "react-easy-crop";
import Button from '../../../components/inputs/Button';

const PostImageCropper = ({ image, onCropDone, onCropCancel }) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedArea, setCroppedArea] = useState(null);
    const [aspectRatio, setAspectRatio] = useState(1); // Default 1:1

    const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
        setCroppedArea(croppedAreaPixels);
    };

    const handleAspectRatioChange = (ratio) => {
        setAspectRatio(ratio);
    };

    return (
        <div className="relative top-20 w-[80vw] h-[90vh] flex flex-col items-center justify-center rounded-lg overflow-hidden bg-background shadow-lg">
            {/* Aspect Ratio Options */}
            <div className="flex gap-4 mb-4">
                <Button  label="Square 1:1"  onClick={() => handleAspectRatioChange(1)} outline={aspectRatio !== 1 }/>
                <Button  label="Portrait 4:5" onClick={() => handleAspectRatioChange(4/5)} outline={aspectRatio !== 4/5} />
                <Button  label="Landscape 16:9" onClick={() => handleAspectRatioChange(16/9)} outline={aspectRatio !== 16/9}/>
                
            </div>

            {/* Cropper Section */}
            <div className="relative w-full h-[60vh] bg-black rounded-lg overflow-hidden">
                <Cropper
                    image={image}
                    aspect={aspectRatio}
                    crop={crop}
                    zoom={zoom}
                    onCropChange={setCrop}
                    onZoomChange={setZoom}
                    onCropComplete={onCropComplete}
                    showGrid={false}
                    cropShape="rect"
                    style={{
                        containerStyle: {
                            height: "100%",
                            backgroundColor: "#000",
                        }
                    }}
                />
            </div>

            {/* Buttons */}
            <div className="flex items-center justify-center gap-4 mt-4">
                <Button label="Zrušiť" onClick={onCropCancel} />
                <Button 
                    label="Orezať a uložiť" 
                    onClick={() => onCropDone(croppedArea)} 
                />
            </div>
        </div>
    );
};

export default PostImageCropper;
