import React, {  useRef, useState, useCallback } from "react";
import Button from ".././components/inputs/Button";
import { useMutation } from "@tanstack/react-query";
import apiRequest from "../lib/ApiRequest";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const VerifyEmail = () => {
  const [code, setCode] = useState(["", "", "", "", "", ""]);
  const inputRefs = useRef([]);
  const navigate = useNavigate();

  const {
    mutate: verifyEmailMutation,
    isLoading,
    error,
  } = useMutation({
    mutationFn: async (code) => {
      const verificationCode = code.join("");
      const res = await apiRequest.post("/users/verify-email", {
        code: verificationCode,
      });
      if (res.status !== 200) {
        throw new Error("Failed to Verify Email.");
      }
      const data = res.data;

      if (!data || typeof data !== "object") {
        throw new Error("Invalid response data format.");
      }

      return data;
    },
    onError: (error) => {
      const message = error.response?.data?.message || "Something went wrong!";
      console.error("Email verification failed", error);
      navigate("/");
      toast.error(message || "An error occurred during login.");
    },
    onSuccess: (data) => {
      console.log("Email verified successfully", data);
      navigate(`/profile/${data.user._id}`);
      toast.success(data.message || "Overenie Emailu bolo uspesne");
    },
  });

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (code.every((digit) => digit !== "")) {
        verifyEmailMutation(code);
      } else {
        toast.error("Please enter the complete 6-digit code.");
      }
    },
    [code, verifyEmailMutation]
  );

  const handleChange = (index, value) => {
    const newCode = [...code];

    // Handle pasted content
    if (value.length > 1) {
      const pastedCode = value.slice(0, 6).split("");
      for (let i = 0; i < 6; i++) {
        newCode[i] = pastedCode[i] || "";
      }
      setCode(newCode);

      // Focus on the last non-empty input or the first empty one
      const lastFilledIndex = newCode.findLastIndex((digit) => digit !== "");
      const focusIndex = lastFilledIndex < 5 ? lastFilledIndex + 1 : 5;
      inputRefs.current[focusIndex].focus();
    } else {
      newCode[index] = value;
      setCode(newCode);

      // Move focus to the next input field if value is entered
      if (value && index < 5) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (index, e) => {
    // Handle backspace to focus on the previous input
    if (e.key === "Backspace" && !code[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  // // Auto-submit form when all inputs are filled
  // useEffect(() => {
  //   if (code.every((digit) => digit !== "")) {
  //     handleSubmit(new Event("submit"));
  //   }
  // }, [code, handleSubmit]);

  return (
    <div
      className="flex flex-col gap-4"
    >
      <h2 className="text-3xl font-bold mb-6 text-center text-mainColor-500 bg-clip-text">
        Over svoj Email
      </h2>
      <p className="text-center mb-6">
        Zadaj 6-miestny kód, ktorý ti bol zaslaný na tvoju e-mailovú adresu.
      </p>

      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="flex justify-center gap-4">
          {code.map((digit, index) => (
            <input
              key={index}
              ref={(el) => (inputRefs.current[index] = el)}
              type="text"
              maxLength="1"
              value={digit}
              onChange={(e) => handleChange(index, e.target.value)}
              onKeyDown={(e) => handleKeyDown(index, e)}
              className="w-12 h-12 text-center text-2xl font-bold bg-white border-2 border-mainColor-400 rounded-lg focus:border-mainColor-700 focus:outline-none"
            />
          ))}
        </div>
        {error && (
          <p className="text-red-500 font-semibold mt-2">{error.message}</p>
        )}
        <Button
          type="submit"
          label={isLoading ? "Verifying..." : "Overiť Email"}
        />
      </form>
    </div>
  );
};

export default VerifyEmail;
