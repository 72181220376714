import { VscAccount } from "react-icons/vsc";
import { formatRelativeTime } from "../../../utils/timeFormatter";
import { FaHeart } from "react-icons/fa6";

const CommentList = ({ comments }) => {
    return (
        <div className="flex flex-col gap-3 max-h-60 overflow-auto">
            {comments.map((comment) => (
                <div key={comment._id} className="flex gap-2 p-2 rounded-lg border border-mainColor-700 bg-background">
                    {/* Profilový obrázok */}
                    <div className="flex-shrink-0">
                        {comment.user.profileImg ? (
                            <img
                                src={comment.user.profileImg}
                                alt="Profile"
                                className="w-8 h-8 rounded-full border border-mainColor-500"
                            />
                        ) : (
                            <VscAccount className="w-8 h-8 text-mainColor-500 rounded-full border border-mainColor-500" />
                        )}
                    </div>

                    {/* Obsah komentára */}
                    <div className="flex flex-col">
                        <div className="flex items-center gap-2">
                            <span className="font-bold text-mainColor">{comment.user.firstName} {comment.user.lastName}</span>
                        </div>
                        <div className="text-sm text-primaryText">{comment.text}</div>

                        {/* Akcie pod komentárom */}
                        <div className="flex items-center gap-4 text-mainColor-500 text-xs mt-1">
                            <span>{formatRelativeTime(comment.createdAt)}</span>
                            <button className="font-semibold hover:underline">Páči sa mi to</button>
                            <button className="font-semibold hover:underline">Odpovedať</button>
                            <FaHeart className="text-mainColor-500 text-xs" />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default CommentList;
