import axios from "axios";

const autoformAPI = axios.create({
    baseURL: "https://autoform.ekosystem.slovensko.digital/api/corporate_bodies",
    params: {
      private_access_token: process.env.REACT_APP_PRIVATE_ACCESS_TOKEN,
    },
    timeout: 5000,
  });

export default autoformAPI;
